import {createRouter, createWebHistory} from 'vue-router';

import LoginForm from "@/pages/auth/LoginForm";
import MainDashboard from "@/components/MainDashboard";

function loadView(view) {
    return () => import(`@/pages/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [{
            path: '/login',
            name: 'login',
            component: LoginForm,
            meta: {
                auth: false
            }
        },
        {
            path: '/SSO/:token',
            name: 'sso',
            component: loadView("auth/LoginSSO"),
            meta: {

            }
        },
        {
            path: '/validate',
            name: 'validate',
            component: loadView("auth/LoginValidate"),
            meta: {
                auth: false
            }
        },
        {
            path: '/passwort-vergessen',
            name: 'forgetPassword',
            component: loadView("auth/LoginForgetPassword"),
            meta: {
                auth: false
            }
        },
        {
            path: '/registieren',
            name: 'register',
            component: loadView("auth/LoginRegister"),
            meta: {
                auth: false
            }
        },
        {
            path: '/',
            name: 'dashboard',
            component: MainDashboard,
            meta: {
                auth: true,
            }
        },
        {
            path: '/vertrag',
            name: 'contract',
            component: loadView("pages/PageContract"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/studio',
            name: 'studio',
            component: loadView("pages/PageStudio"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/kk',
            name: 'kk',
            component: loadView("pages/PageKk"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/zusatz',
            name: 'additions',
            component: loadView("additions/AdditionBuy"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/partner',
            name: 'partner',
            component: loadView("pages/PagePartner"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/faq',
            name: 'faq',
            component: loadView("pages/PageFaq"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/kontakt',
            name: 'contact',
            component: loadView("pages/PageContact"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/live-group-fitness',
            name: 'lgf',
            component: loadView("pages/PageLGF"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/payment/success',
            name: 'success',
            component: loadView("pages/PaymentSuccess"),
            meta: {
                auth: true,
            }
        },
        {
            path: '/payment/failed',
            name: 'failed',
            component: loadView("pages/PaymentFailed"),
            meta: {
                auth: true,
            }
        },
    ]
});

export default (app) => {
    app.router = router;
    app.use(router);
}